<template>
  <v-container>
    <v-row>
      <v-breadcrumbs :items="breadCrumbsItems" large>
        <template v-slot:divider>
          <v-icon size="11">mdi-greater-than</v-icon>
        </template>
      </v-breadcrumbs>
    </v-row>

    <v-row class="rowBg" v-if="userDetails.isActive">
      <!-- <v-col cols="12" md="3" class="hidden-sm-and-down grayBg">
        <v-layout>
          <h6><b>FILTER BY PRICE</b></h6>
        </v-layout>
        <v-layout wrap>
          <v-col cols="12" class="px-4">
            <v-range-slider
              v-model="range"
              :max="max"
              :min="min"
              hide-details
              class="align-center"
            >
            </v-range-slider>
          </v-col>
        </v-layout>

        <v-layout wrap>
          <v-col cols="12">
            <v-text-field
              :value="range[0]"
              class="mt-0 pt-0"
              hide-details
              single-line
              type="number"
              @change="$set(range, 0, $event)"
            ></v-text-field>
          </v-col>
        </v-layout>
        <v-layout wrap>
          <v-col cols="12">
            <v-text-field
              :value="range[1]"
              class="mt-0 pt-0"
              hide-details
              single-line
              type="number"
              @change="$set(range, 1, $event)"
            ></v-text-field>
          </v-col>
        </v-layout>
        <v-layout wrap>
          <v-col cols="6">
            <v-btn small class="buttonStyle">Filter</v-btn>
          </v-col>
        </v-layout>

        <v-layout class="mt-10">
          <h6><b>BROWSE CATEGORIES</b></h6>
        </v-layout>

        <v-layout wrap>
          <v-col cols="12">
            <v-list color="#F2F2F2" style="border-radius:10px;">
              <v-list-item-group>
                <v-list-item v-for="(item, i) in categories" :key="i">
                  <v-list-item-icon>
                    <v-icon color="#7CBF46">mdi-pill</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-layout>

        <v-layout class="mt-5">
          <h6><b>BROWSE REGIONS</b></h6>
        </v-layout>
      </v-col> -->
      <v-col cols="12">
        <!--Recent ManufacturersProduct Products-->
        <v-row class="mb-5 ml-3">
          <v-col cols="12">
            <h5>Available Products from Manufactures</h5>
            <v-row
              wrap
              class="pa-10"
              style="background-color: #f2f2f2"
              v-if="manufacturesProducts.length >= 1"
            >
              <v-col
                cols="6"
                md="3"
                v-for="(product, index) in manufacturesProducts"
                :key="index"
              >
                <v-img
                  height="150"
                  :src="product.productImagePath"
                  cover
                  style="border-radius:15px 15px 0px 0px;"
                  @click="viewSingleProduct(product)"
                >
                  
                  <h6 align="center" class="priceStyle2" v-if="product.needsPrescription == false">
                    Sale!
                  </h6>

                  <h6 align="center" class="priceStyle3" v-if="product.needsPrescription">
                    Prescription
                  </h6>
                </v-img>

                <p style="background-color:#ffffff; padding:10px;">
                  <span style="font-size:12px;">{{
                    product.ProductCategory.name
                  }}</span>
                  <br />
                  <span style="font-size:12px;"
                    ><b>{{ product.name }}</b></span
                  >
                  <br />
                  <span
                    ><b
                      >{{ systemSetting.systemCurrency }} {{ product.price }}</b
                    >
                  </span>
                  <br/>
                  <v-btn class="buttonStyle mr-5" small  @click="viewSingleProduct(product)">
                            Buy Now
                          </v-btn>
                </p>
              </v-col>
              <v-dialog v-model="dialogSingleProduct" width="800">
                <v-card>
                  <v-card-text class="mt-4">
                    <v-row wrap>
                      <v-col cols="6">
                        <v-img
                          height="250"
                          :src="singleProduct.productImagePath"
                          contain
                        />
                      </v-col>
                      <v-col cols="6">
                        <div>
                          <h4>{{ singleProduct.name }}</h4>
                          <p>
                            <b>
                              {{ systemSetting.systemCurrency }}
                              {{ singleProduct.price }}
                            </b>
                          </p>
                          <p>
                            {{ singleProduct.description }}
                          </p>
                          <p><b>Availability:</b> In Stock</p>
                          <p>
                            <b>Quantity:</b>
                            <span class="ml-5">
                              <v-icon
                                dark
                                color="#72bd61"
                                class="mr-3"
                                @click="decreamentCounter"
                                >mdi-minus-circle</v-icon
                              >
                              {{ cartData.quantity }}
                              <v-icon
                                dark
                                color="#72bd61"
                                class="ml-3"
                                @click="increamentCounter"
                                >mdi-plus-circle</v-icon
                              >
                            </span>
                          </p>
                          <p class="mt-5" style="color:#F01010">
                            Quantity supplies are measured in Cartoons
                          </p>
                        </div>

                        <v-divider></v-divider>

                        <div>
                          <v-btn class="buttonStyle mr-5 ml-5" @click="addCart">
                            Add to Cart
                          </v-btn>

                          <v-btn class="buttonStyleGray" :to="'/cart/overview'">
                            Checkout
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogAddToCart" width="700" persistent>
                <v-card>
                  <v-card-text>
                    <v-row>
                      <v-col cols="11" align="center">
                        <v-icon size="60" color="#72BD61"
                          >mdi-check-circle</v-icon
                        >
                        <br />
                        <span style="font-size:16px;">
                          {{ singleProduct.name }} has been added to your
                          shopping cart.
                        </span>
                        <br/>
                          <v-btn class="buttonStyle mr-5 ml-5 mt-5" @click="reloadPage">
                            Continue
                          </v-btn>
                      </v-col>

                      <v-col cols="1" align="right">
                        <v-btn
                          icon
                          color="black"
                          @click="reloadPage"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>

                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-row>
           

            <v-row wrap class="pa-10" v-if="manufacturesProducts.length <= 0">
              <v-col cols="12" align="center" justify="center">
                <p>
                  <v-img
                    alt="System Logo"
                    class="shrink mr-2"
                    contain
                    :src="'img/emptyProductList.gif'"
                    transition="scale-transition"
                    width="350"
                  />
                </p>
                <p class="text-h5">
                  No Product Available
                </p>
              </v-col>
            </v-row>

            <!---- Edit Member Profile---->
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row wrap v-else class="mb-10">
      <v-col cols="12" align="Center">
          <v-layout wrap>
                <v-col cols="12" align="Center">
                  <v-icon size="200" color="red">mdi-alert-circle</v-icon>
                </v-col>
          </v-layout>
          <h3>Account awaiting approval</h3>
          <v-layout wrap>
                <v-col cols="12" md="10" offset-md="1" align="Center">
                  <p>Hello <b>{{userDetails.name}}</b>! We are glad to have you sign up on qikPharma as a <b>Distributor</b>. Your account is currently under review and will be approved sooner than you can imagine.</p>
                </v-col>
          </v-layout>
        </v-col>
      </v-row>


  </v-container>
</template>

<script>
export default {
  name: "productFromManufactures",

  created() {
    this.distributorId = localStorage.getItem("distributorId");
    this.getUserDetails();
    this.getRegions();
    this.getProductCat();
    this.getManufacturesProducts();
  },

  data: () => ({
    dialogAddToCart:false,
    dialogSingleProduct:false,
    distributorId: "",
    min: 100,
    max: 70000,
    range: [-20, 70],
    categories: [],
    singleProduct: {},
    manufacturesProducts: [],
    roleBody: {
      name: "manufacturer",
    },
    breadCrumbsItems: [
      {
        text: "Home",
        disabled: false,
        href: "/#/overview/distributor_dashboard/en/home",
      },
      {
        text: "Products from Manufacturesrs",
        disabled: true,
        href: "/#/en/products-from-manufactures",
      },
    ],
    userDetails:{
      isActive:true,
    },
    systemSetting: {
      systemCurrency: "GH₵",
    },
    cartData: {
      productId: "",
      quantity: 1,
    },
  }),
  methods: {
    getUserDetails() {
      this.$http
        .get(`${process.env.VUE_APP_URL}user/` + localStorage.getItem("userId"))
        .then((response) => {
          this.userDetails = response.data.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    getRegions() {
      this.$http
        .get(`${process.env.VUE_APP_URL}regions`)
        .then((response) => {
          this.productRegion = response.data.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    getProductCat() {
      this.$http
        .get(`${process.env.VUE_APP_URL}product-categories`)
        .then((response) => {
          this.categories = response.data.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    getManufacturesProducts() {
      this.$http
        .post(`${process.env.VUE_APP_URL}product/role`, this.roleBody)
        .then((response) => {
          if (response.data.success == true) {
            this.manufacturesProducts = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    increamentCounter() {
      this.cartData.quantity = this.cartData.quantity + 1;
    },
    decreamentCounter() {
      if (this.cartData.quantity <= 0) {
        this.cartData.quantity = 1;
      }
      this.cartData.quantity = this.cartData.quantity - 1;
    },
    viewSingleProduct(product) {
      this.dialogSingleProduct = true;
      this.singleProduct = product;
      this.cartData.productId = product.productId;
    },
    addCart() {
      this.$http
        .post(
          `${process.env.VUE_APP_URL}cart/add/` +
            localStorage.getItem("userId"),
          this.cartData
        )
        .then((response) => {
          this.dialogAddToCart = true;
          const cartListItems = response.data.data.CartItems;
          let lastCartItem = cartListItems[cartListItems.length - 1];
          let recentCart = JSON.parse(localStorage.getItem("cartItems"));
          recentCart.push(lastCartItem);
        })
        .catch((error) => {
          console.log(error.reponse);
        });
    },
    reloadPage(){
      location.reload();
    }
  },
};
</script>

<style scoped>
.rowBg {
  background-color: #ffffff;
  padding: 10px;
}

.priceStyle2 {
  width: 80px;
  height: auto;
  background-color: #bd2121;
  color: #ffffff;
  padding: 5px;
  border-bottom-right-radius: 5px;
}

.priceStyle3 {
  width: 100px;
  font-size: 12px;
  height: auto;
  background-color: #198754;
  color: #ffffff;
  padding: 5px;
  border-bottom-right-radius: 5px;
}


.grayBg {
  background-color: #f2f2f2;
  padding: 20px;
}

.paddingRow {
  padding: 50px;
}

.orderStatBox {
  padding: 30px !important;
  width: 120px;
  height: 80px;
  border-radius: 10px;
  text-align: center;
  margin: 10px;
}

.orderStatText {
  color: #000000;
  font-size: 14px;
  text-align: center;
}

.buttonStyle {
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 10px;
}
</style>
